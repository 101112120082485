import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: "Geri Rosenberg",
  lang: '',
  description: 'GIS Analyst and Planner',
};

// HERO DATA
export const heroData = {
  title: "Hi, it's",
  name: 'Geri Rosenberg',
  subtitle: "I'm a GIS Analyst and Planner with a passion for promoting walkable communities and protecting public lands.",
  cta: 'More about my work',
};

// ABOUT DATA
export const aboutData = {
  img: 'headshot.png',
  paragraphOne: "I'm a storyteller. I turn big globs of databases into something meaningful to humans. As a visual thinker, I bring a clear and well-balanced perspective to analyses and visualizations, whether print or interactive. I believe in equity for all communities, and I apply that to all of my work, from the research questions I design, to the data I collect, to the way I design my maps and charts. I strive to make maps as simple as possible while still conveying the whole story and telling a clear narrative.",
  paragraphTwo: "I've worn many hats throughout my career, at various points becoming a coalition leader, an event planner, an economic development specialist, and a rural affordable housing expert. I believe teams with the most diverse backgrounds develop the most innovative solutions.",
  paragraphThree: "I'm also a cyclist, runner, and long-distance backpacker. My personal battery runs on big goals, like thru-hiking a 500-mile mountain wilderness trail and running my first half-marathon in the middle of a pandemic. I apply this goal-oriented lifestyle to all parts of my work, from writing minutely-detailed workplans to setting and sticking to every deadline.",
  resume: 'resume',
  email: 'geri@itsgeri.com'
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'CarelessDecay.jpg',
    title: 'Careless Decay: Dental Deserts in the United States',
    info: 'Interactive story map awarded first place in NACIS competition',
    info2: '',
    url: 'https://gerirosenberg.github.io/careless-decay/',
    repo: 'https://github.com/gerirosenberg/careless-decay',
  },
  {
    id: nanoid(),
    img: 'DCWork.jpg',
    title: 'How the DC Region Gets to Work',
    info: 'D3 interactive map of D.C. region commuting data by census tracts. Responsive scatterplot maps commuting data by tract median income. Infolabels on tract/dot mouseover.',
    info2: '',
    url: 'https://gerirosenberg.github.io/d3-coordinated-viz/',
    repo: 'https://github.com/gerirosenberg/d3-coordinated-viz',
  },
  {
    id: nanoid(),
    img: 'EconImpact.jpg',
    title: 'Economic Impacts of Public Transportation in the Commonwealth of Virginia',
    info: 'Economic analysis of 41 transit agencies in Virginia, accounting for 175M annual ridership, $575M+ in payroll, and $480M+ in capital spending',
    info2: '',
    url: 'http://www.drpt.virginia.gov/media/3263/drpt-eit-in-va-executive-summary_final_112320.pdf',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'RuralAffordable.jpg',
    title: 'Providing Well-Placed Affordable Housing in Rural Communities',
    info: 'Rural affordable housing policy toolkit',
    info2: '',
    url: 'https://smartgrowthamerica.org/wp-content/uploads/2017/04/rural-toolkit_affordable-housing.pdf',
    repo: '',
  },
    {
    id: nanoid(),
    img: 'BRT.jpg',
    title: 'Best Practices in Rapid Transit System Design',
    info: 'A Bus Rapid Transit design guide for residents, advocates, and policymakers in Montgomery County, MD',
    info2: '',
    url: 'https://www.smartergrowth.net/wp-content/uploads/2015/08/2015-BRT-best-practices-guide.pdf',
    repo: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: 'Reach out',
  email: 'geri@itsgeri.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'http://www.linkedin.com/in/geri-rosenberg/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'http://github.com/gerirosenberg',
    }
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false,
};
